var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.websites.length === 0)?_c('v-row',{attrs:{"dense":""}},_vm._l((3),function(index){return _c('v-col',{key:index,staticStyle:{"padding":"8px"},attrs:{"cols":"12","md":"4"}},[_c('v-skeleton-loader',{attrs:{"type":"card, list-item-three-line","elevation":"2"}})],1)}),1):_c('v-row',{attrs:{"dense":""}},[_c('draggable',_vm._b({staticStyle:{"width":"100%"},model:{value:(_vm.draggedWebsites),callback:function ($$v) {_vm.draggedWebsites=$$v},expression:"draggedWebsites"}},'draggable',_vm.dragOptions,false),[_c('transition-group',{staticClass:"row wrap"},_vm._l((_vm.draggedWebsites),function(site,index){return _c('v-col',{key:site.website_id,attrs:{"cols":"12","md":"4"}},[_c('SiteCard',_vm._g({attrs:{"site":site,"additional_info":[
            {
              'title': 'API User',
              'info': site.api_user,
              'show': false
            },
            {
              'title': 'API Key',
              'info': site.api_key,
              'show': false
            },
            {
              'title': 'URL',
              'info': site.url,
              'show': true
            },
          ],"index":index,"access":_vm.access,"syncWebsite":_vm.syncWebsite,"goToWebsite":_vm.goToWebsite}},_vm.$listeners))],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }